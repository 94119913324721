var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"suDetailImgShowBox",class:{
        detailImgShowBox: _vm.type == 'material',
        suDetailImgShowBox: _vm.type == 'su' || _vm.type == 'max'  || _vm.type == 'schemeText' || _vm.type == 'chartlet',
      }},[_c('div',{class:{
          mainShowImg: _vm.type == 'material',
          suMainShowImg: _vm.type == 'su' || _vm.type == 'max'  || _vm.type == 'schemeText',
          chartletMainShowImg: _vm.type == 'chartlet',
        }},[_c('el-carousel',{ref:"carousel",attrs:{"indicator-position":"none","height":_vm.type == 'material' ? '5.74rem' : _vm.type == 'chartlet' ? '6.6rem' :'6.59rem',"autoplay":false},on:{"change":_vm.changeImg}},_vm._l((_vm.srcList),function(item,index){return _c('el-carousel-item',{key:index},[(item)?_c('el-image',{class:{ img: _vm.type == 'material', suImg: _vm.type == 'su' || _vm.type == 'max'  || _vm.type == 'schemeText', chartletImg: _vm.type == 'chartlet'},attrs:{"ondragstart":"return false","oncontextmenu":"return false","src":item,"preview-src-list":_vm.srcList}}):_vm._e()],1)}),1)],1)]),_c('div',{staticClass:"card-carousel-wrapper"},[_c('div',{staticClass:"card-carousel--nav__left",attrs:{"disabled":_vm.atHeadOfList},on:{"click":function($event){return _vm.moveCarousel(-1)}}}),_c('div',{staticClass:"card-carousel"},[_c('div',{staticClass:"card-carousel--overflow-container"},[_c('div',{staticClass:"card-carousel-cards",style:({
              transform: 'translateX' + '(' + _vm.currentOffset + 'px' + ')',
            })},_vm._l((_vm.srcList),function(item,index){return _c('div',{key:index,staticClass:"card-carousel--card",class:{ active: _vm.mainImgIndex == index },on:{"click":function($event){return _vm.showManImg(index)}}},[_c('img',{class:{ sImg: _vm.type == 'material', sSuImg: _vm.type == 'su' || _vm.type == 'max'  || _vm.type == 'schemeText', chartletImg: _vm.type == 'chartlet'},attrs:{"ondragstart":"return false","oncontextmenu":"return false","src":item,"alt":""}})])}),0)])]),_c('div',{staticClass:"card-carousel--nav__right",attrs:{"disabled":_vm.atEndOfList},on:{"click":function($event){return _vm.moveCarousel(1)}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }