<template>
  <div>
    <div
      :class="{
        detailImgShowBox: type == 'material',
        suDetailImgShowBox: type == 'su' || type == 'max'  || type == 'schemeText' || type == 'chartlet',
      }"
      class="suDetailImgShowBox"
    >
      <div
        :class="{
          mainShowImg: type == 'material',
          suMainShowImg: type == 'su' || type == 'max'  || type == 'schemeText',
          chartletMainShowImg: type == 'chartlet',
        }"
      >
        <el-carousel
          ref="carousel"
          indicator-position="none"
          :height="type == 'material' ? '5.74rem' : type == 'chartlet' ? '6.6rem' :'6.59rem'"
					
          :autoplay="false"
          @change="changeImg"
        >
          <el-carousel-item v-for="(item, index) in srcList" :key="index">
            <el-image
							ondragstart="return false" oncontextmenu="return false"
							v-if="item"
              :src="item"
              :preview-src-list="srcList"
              :class="{ img: type == 'material', suImg: type == 'su' || type == 'max'  || type == 'schemeText', chartletImg: type == 'chartlet'}"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="card-carousel-wrapper">
      <div
        class="card-carousel--nav__left"
        @click="moveCarousel(-1)"
        :disabled="atHeadOfList"
      ></div>
      <div class="card-carousel">
        <div class="card-carousel--overflow-container">
          <div
            class="card-carousel-cards"
            :style="{
              transform: 'translateX' + '(' + currentOffset + 'px' + ')',
            }"
          >
            <div
              class="card-carousel--card"
              v-for="(item, index) in srcList"
              :key="index"
              :class="{ active: mainImgIndex == index }"
              @click="showManImg(index)"
            >
              <img
								ondragstart="return false" oncontextmenu="return false"
                :src="item"
                alt=""
                :class="{ sImg: type == 'material', sSuImg: type == 'su' || type == 'max'  || type == 'schemeText', chartletImg: type == 'chartlet'}"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="card-carousel--nav__right"
        @click="moveCarousel(1)"
        :disabled="atEndOfList"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailImgShow",
  props: ["srcList", "type"],
  data() {
    return {
      currentOffset: 0,
      paginationFactor: 168,
      mainImgIndex: 0,
    };
  },
  created() {
    this.paginationFactor = this.type == "su" ? 212 : 168;
  },
  computed: {
    atEndOfList() {
      return (
        this.currentOffset <=
        this.paginationFactor * -1 * (this.srcList.length - 4)
      );
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
  },
  mounted() {},
  methods: {
    changeImg(value) {
      this.mainImgIndex = value;
    },
    moveCarousel(direction) {
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
      }
    },
    showManImg(index) {
      this.mainImgIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },
  },
};
</script>

<style scoped>
.img {
  top: 50%;
  left: 36%;
  transform: translate(-50%, -50%);
}
.suImg >>> .el-image__preview {
  height: 6.59rem !important;
}
.chartletImg >>> .el-image__preview {
  height: 6.59rem !important;
	-o-object-fit: contain;
	object-fit: contain;
}

.card-carousel--card {
  border: 0.02rem solid #42b88300;
}

.card-carousel--card.active {
  border: 0.02rem solid #42b883;
}

.card-carousel-cards .card-carousel--card .sImg {
  width: 1.68rem;
  height: 1.68rem;
  vertical-align: bottom;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: opacity 150ms linear;
  user-select: none;
}
.card-carousel-cards .card-carousel--card .sSuImg {
  width: 2.12rem;
  height: 1.59rem;
  vertical-align: bottom;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: opacity 150ms linear;
  user-select: none;
}

.card-carousel-cards .card-carousel--card .chartletImg {
  width: 2.12rem;
  height: 1.59rem;
  vertical-align: bottom;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: opacity 150ms linear;
  user-select: none;
	-o-object-fit: contain;
	object-fit: contain;
}
.card-carousel-cards .card-carousel--card:first-child {
  margin-left: 0;
}

.card-carousel-cards .card-carousel--card {
  margin: 0 0.1rem;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
}

.card-carousel-cards {
  display: flex;
  transition: transform 150ms ease-out;
  transform: translatex(0px);
}

.card-carousel--overflow-container {
  overflow: hidden;
}

.card-carousel {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card-carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  color: #666a73;
}

.card-carousel--nav__left[disabled],
.card-carousel--nav__right[disabled] {
  opacity: 0.2;
  border-color: black;
}

.card-carousel--nav__left[disabled],
.card-carousel--nav__right[disabled] {
  opacity: 0.2;
  border-color: black;
}

.card-carousel--nav__right {
  transform: rotate(45deg);
}

.card-carousel--nav__left {
  transform: rotate(-135deg);
}

.card-carousel--nav__left,
.card-carousel--nav__right {
  display: inline-block;
  width: 0.15rem;
  height: 0.15rem;
  padding: 0.1rem;
  box-sizing: border-box;
  border-top: 0.02rem solid #42b883;
  border-right: 0.02rem solid #42b883;
  cursor: pointer;
  margin: 0 0.1rem;
  transition: transform 150ms linear;
}

.suDetailImgShowBox {
  background: #f5f5f5;
  height: 6.59rem;
}

.detailImgShowBox {
  background: #f5f5f5;
  height: 5.74rem;
}

.mainShowImg {
  width: 100%;
  /* height: 5.74rem; */
  text-align: center;
  overflow: hidden;
  /*display: flex;*/
  /*align-items: center;*/
}
.suMainShowImg {
  width: 100%;
  /* height: 5.74rem; */
  text-align: center;
  overflow: hidden;
}
.chartletMainShowImg {
	width: 8.8rem;
  text-align: center;
  overflow: hidden;
}

.mainShowImg >>> img {
  max-width: 5.74rem;
}
</style>